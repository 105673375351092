export const API_ROOT = 'https://statsapi.web.nhl.com';
// export const API_URL = `${API_ROOT}/api/v1`;
// export const SCHEDULE_URL = `${API_URL}/schedule?expand=schedule.linescore`;
// export const DEV_GAME_URL = `${API_URL}/schedule?date=2018-01-09&`;
// export const CONTENT_URL = `${API_URL}/api/v1/game/{$GAMEID}/content`;
export const IMAGE_URL = 'https://nhl.bamcontent.com/images';

// export const PLAYER_GAME_LOG_URL = '/stats?stats=gameLog';
// export const PLAYER_PACE_URL = '/stats?stats=onPaceRegularSeason';
// export const PLAYER_STATS_URL = '/stats?stats=statsSingleSeason';
