import {
  Form,
  FormError,
  FieldError,
  Label,
  NumberField,
  Submit,
} from '@redwoodjs/web';

const PoolForm = (props) => {
  const onSubmit = (data) => {
    props.onSave(data, props?.pool?.id);
  };

  return (
    <div className="rw-form-wrapper">
      <Form onSubmit={onSubmit} error={props.error}>
        <FormError
          error={props.error}
          wrapperClassName="rw-form-error-wrapper"
          titleClassName="rw-form-error-title"
          listClassName="rw-form-error-list"
        />

        <Label
          name="center"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Center
        </Label>
        <NumberField
          name="center"
          defaultValue={props.pool?.center}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{ required: false }}
        />
        <FieldError name="center" className="rw-field-error" />

        <Label
          name="leftWing"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Left wing
        </Label>
        <NumberField
          name="leftWing"
          defaultValue={props.pool?.leftWing}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{ required: false }}
        />
        <FieldError name="leftWing" className="rw-field-error" />

        <Label
          name="rightWing"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Right wing
        </Label>
        <NumberField
          name="rightWing"
          defaultValue={props.pool?.rightWing}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{ required: false }}
        />
        <FieldError name="rightWing" className="rw-field-error" />

        <Label
          name="defense"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Defense
        </Label>
        <NumberField
          name="defense"
          defaultValue={props.pool?.defense}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{ required: false }}
        />
        <FieldError name="defense" className="rw-field-error" />

        <Label
          name="utility"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Utility
        </Label>
        <NumberField
          name="utility"
          defaultValue={props.pool?.utility}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{ required: false }}
        />
        <FieldError name="utility" className="rw-field-error" />

        <Label
          name="goalie"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Goalie
        </Label>
        <NumberField
          name="goalie"
          defaultValue={props.pool?.goalie}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{ required: false }}
        />
        <FieldError name="goalie" className="rw-field-error" />

        <Label
          name="g"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          G
        </Label>
        <NumberField
          name="g"
          defaultValue={props.pool?.g}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{ required: false }}
        />
        <FieldError name="g" className="rw-field-error" />

        <Label
          name="a"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          A
        </Label>
        <NumberField
          name="a"
          defaultValue={props.pool?.a}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{ required: false }}
        />
        <FieldError name="a" className="rw-field-error" />

        <Label
          name="ppp"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Ppp
        </Label>
        <NumberField
          name="ppp"
          defaultValue={props.pool?.ppp}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{ required: false }}
        />
        <FieldError name="ppp" className="rw-field-error" />

        <Label
          name="shg"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Shg
        </Label>
        <NumberField
          name="shg"
          defaultValue={props.pool?.shg}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{ required: false }}
        />
        <FieldError name="shg" className="rw-field-error" />

        <Label
          name="sha"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Sha
        </Label>
        <NumberField
          name="sha"
          defaultValue={props.pool?.sha}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{ required: false }}
        />
        <FieldError name="sha" className="rw-field-error" />

        <Label
          name="gwg"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Gwg
        </Label>
        <NumberField
          name="gwg"
          defaultValue={props.pool?.gwg}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{ required: false }}
        />
        <FieldError name="gwg" className="rw-field-error" />

        <Label
          name="hit"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Hit
        </Label>
        <NumberField
          name="hit"
          defaultValue={props.pool?.hit}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{ required: false }}
        />
        <FieldError name="hit" className="rw-field-error" />

        <Label
          name="shot"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Shot
        </Label>
        <NumberField
          name="shot"
          defaultValue={props.pool?.shot}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{ required: false }}
        />
        <FieldError name="shot" className="rw-field-error" />

        <Label
          name="blk"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Blk
        </Label>
        <NumberField
          name="blk"
          defaultValue={props.pool?.blk}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{ required: false }}
        />
        <FieldError name="blk" className="rw-field-error" />

        <Label
          name="w"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          W
        </Label>
        <NumberField
          name="w"
          defaultValue={props.pool?.w}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{ required: false }}
        />
        <FieldError name="w" className="rw-field-error" />

        <Label
          name="so"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          So
        </Label>
        <NumberField
          name="so"
          defaultValue={props.pool?.so}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{ required: false }}
        />
        <FieldError name="so" className="rw-field-error" />

        <Label
          name="sa"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Sa
        </Label>
        <NumberField
          name="sa"
          defaultValue={props.pool?.sa}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{ required: false }}
        />
        <FieldError name="sa" className="rw-field-error" />

        <Label
          name="ga"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Ga
        </Label>
        <NumberField
          name="ga"
          defaultValue={props.pool?.ga}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{ required: false }}
        />
        <FieldError name="ga" className="rw-field-error" />

        <Label
          name="gg"
          className="rw-label"
          errorClassName="rw-label rw-label-error"
        >
          Gg
        </Label>
        <NumberField
          name="gg"
          defaultValue={props.pool?.gg}
          className="rw-input"
          errorClassName="rw-input rw-input-error"
          validation={{ required: false }}
        />
        <FieldError name="gg" className="rw-field-error" />

        <div className="rw-button-group">
          <Submit disabled={props.loading} className="rw-button rw-button-blue">
            Save
          </Submit>
        </div>
      </Form>
    </div>
  );
};

export default PoolForm;
