import { routes, navigate } from '@redwoodjs/router';
import Header from 'src/components/Header';
import { Button, Pane } from 'evergreen-ui';

const RostersLayout = ({ children }) => {
  return (
    <div>
      <Header></Header>
      <main>
        <Pane className="mx-4 pb-4">{children}</Pane>
      </main>
    </div>
  );
};

export default RostersLayout;
