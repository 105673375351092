import { routes, navigate } from '@redwoodjs/router';
import { useMutation } from '@redwoodjs/web';
import { Table } from 'evergreen-ui';

import { IMAGE_URL } from '../../constants/urls';

const imageUrl = `${IMAGE_URL}/headshots/current/168x168`;

const DELETE_PLAYER_MUTATION = gql`
  mutation DeletePlayerMutation($id: Int!) {
    deletePlayer(id: $id) {
      id
    }
  }
`;

const PlayerRow = ({ player }) => {
  const [deletePlayer] = useMutation(DELETE_PLAYER_MUTATION);

  const onDeleteClick = (id) => {
    if (confirm('Are you sure you want to delete player ' + id + '?')) {
      deletePlayer({ variables: { id }, refetchQueries: ['PLAYERS'] });
    }
  };

  return (
    <Table.Row
      isSelectable
      onSelect={() => navigate(routes.player({ id: player.id }))}
    >
      <Table.TextCell className="player-table__cell" flexBasis={200}>
        <img
          src={`${imageUrl}/${player.id}.jpg`}
          height="40"
          width="40"
          style={{
            display: 'inline',
            borderRadius: '50%',
            border: '1px solid gray',
          }}
        ></img>{' '}
        {player.name}
      </Table.TextCell>
      <Table.TextCell className="player-table__cell">
        {player.position}
      </Table.TextCell>
      {player.position !== 'G' ? (
        <>
          <Table.TextCell className="player-table__cell" isNumber>
            {player.g}
          </Table.TextCell>
          <Table.TextCell className="player-table__cell" isNumber>
            {player.a}
          </Table.TextCell>
          <Table.TextCell className="player-table__cell" isNumber>
            {player.ppp}
          </Table.TextCell>
          <Table.TextCell className="player-table__cell" isNumber>
            {player.shg}
          </Table.TextCell>
          <Table.TextCell className="player-table__cell" isNumber>
            {player.sha}
          </Table.TextCell>
          <Table.TextCell className="player-table__cell" isNumber>
            {player.gwg}
          </Table.TextCell>
          <Table.TextCell className="player-table__cell" isNumber>
            {player.hit}
          </Table.TextCell>
          <Table.TextCell className="player-table__cell" isNumber>
            {player.shot}
          </Table.TextCell>
          <Table.TextCell className="player-table__cell" isNumber>
            {player.blk}
          </Table.TextCell>
          <Table.TextCell className="player-table__cell">-</Table.TextCell>
          <Table.TextCell className="player-table__cell">-</Table.TextCell>
          <Table.TextCell className="player-table__cell">-</Table.TextCell>
          <Table.TextCell className="player-table__cell">-</Table.TextCell>
          <Table.TextCell className="player-table__cell" isNumber>
            {Math.round((player.total + Number.EPSILON) * 100) / 100}
          </Table.TextCell>
        </>
      ) : (
        <>
          <Table.TextCell className="player-table__cell">-</Table.TextCell>
          <Table.TextCell className="player-table__cell">-</Table.TextCell>
          <Table.TextCell className="player-table__cell">-</Table.TextCell>
          <Table.TextCell className="player-table__cell">-</Table.TextCell>
          <Table.TextCell className="player-table__cell">-</Table.TextCell>
          <Table.TextCell className="player-table__cell">-</Table.TextCell>
          <Table.TextCell className="player-table__cell">-</Table.TextCell>
          <Table.TextCell className="player-table__cell">-</Table.TextCell>
          <Table.TextCell className="player-table__cell">-</Table.TextCell>
          <Table.TextCell className="player-table__cell" isNumber>
            {player.w}
          </Table.TextCell>
          <Table.TextCell className="player-table__cell" isNumber>
            {player.so}
          </Table.TextCell>
          <Table.TextCell className="player-table__cell" isNumber>
            {player.sa}
          </Table.TextCell>
          <Table.TextCell className="player-table__cell" isNumber>
            {player.ga}
          </Table.TextCell>
          <Table.TextCell className="player-table__cell" isNumber>
            {Math.round((player.total + Number.EPSILON) * 100) / 100}
          </Table.TextCell>
        </>
      )}

      {/* Helpful buttons */}
      {/* <Table.TextCell>
        <Pane display="flex" alignItems="center">

          <Button
            is="a"
            marginRight={minorScale(2)}
            href={routes.player({ id: player.id })}
            title={'Show player ' + player.id}
            appearance="primary"
          >Show</Button>

          <Button
            is="a"
            marginRight={minorScale(2)}
            href={routes.editPlayer({ id: player.id })}
            title={'Edit player ' + player.id}
          >Edit</Button>

          <Button
            onClick={() => onDeleteClick(player.id)}
            title={'Delete player ' + player.id}
            appearance="primary"
            intent="danger"
          >Delete</Button>

        </Pane>
      </Table.TextCell> */}
    </Table.Row>
  );
};

export default PlayerRow;
