import { useState } from 'react';
import { filter } from 'fuzzaldrin-plus';
import { Table } from 'evergreen-ui';
import PlayerTableRow from 'src/components/PlayerTableRow';

import '../../styles/playerTable.scss';

const PlayerTable = ({ players }) => {
  const [query, setQuery] = useState('');

  const handleFilterChange = (value) => {
    setQuery(value);
  };

  const filterData = (players) => {
    const searchQuery = query.trim();

    // If the searchQuery is empty, return the profiles as is.
    if (searchQuery.length === 0) return players;

    return players.filter((player) => {
      // Use the filter from fuzzaldrin-plus to filter by name.
      const result = filter([player.name], searchQuery);
      return result.length === 1;
    });
  };

  const sort = (profiles) => {
    const ordering = ['C', 'LW', 'RW', 'D', 'G'];

    // Property to sort each player on.
    let propKey = 'position';

    return profiles.sort((a, b) => {
      let aValue = a[propKey];
      let bValue = b[propKey];
      let indexA = ordering.indexOf(aValue);
      let indexB = ordering.indexOf(bValue);

      // Support string comparison
      const sortTable = { true: 1, false: -1 };

      return indexA === indexB ? 0 : sortTable[indexA > indexB];
    });
  };

  const playersFiltered = sort(filterData(players));

  return (
    <Table className="mt-4 overflow-x-scroll" border>
      <Table.Head>
        <Table.SearchHeaderCell
          flexBasis={200}
          onChange={handleFilterChange}
          value={query}
        />
        <Table.TextHeaderCell>Position</Table.TextHeaderCell>
        <Table.TextHeaderCell>G</Table.TextHeaderCell>
        <Table.TextHeaderCell>A</Table.TextHeaderCell>
        <Table.TextHeaderCell>PPP</Table.TextHeaderCell>
        <Table.TextHeaderCell>SHG</Table.TextHeaderCell>
        <Table.TextHeaderCell>SHA</Table.TextHeaderCell>
        <Table.TextHeaderCell>GWG</Table.TextHeaderCell>
        <Table.TextHeaderCell>HIT</Table.TextHeaderCell>
        <Table.TextHeaderCell>SHOT</Table.TextHeaderCell>
        <Table.TextHeaderCell>BLK</Table.TextHeaderCell>
        <Table.TextHeaderCell>W</Table.TextHeaderCell>
        <Table.TextHeaderCell>SO</Table.TextHeaderCell>
        <Table.TextHeaderCell>SA</Table.TextHeaderCell>
        <Table.TextHeaderCell>GA</Table.TextHeaderCell>
        <Table.TextHeaderCell>Total</Table.TextHeaderCell>
      </Table.Head>
      <Table.Body>
        {playersFiltered.map((player) => (
          <PlayerTableRow player={player} key={player.id} />
        ))}
      </Table.Body>
    </Table>
  );
};

export default PlayerTable;
