import { useContext } from 'react';
import ThemeContext from 'src/components/ThemeContext';
import { Pane, Spinner } from 'evergreen-ui';

const Loading = () => {
  const theme = useContext(ThemeContext);

  return (
    <Pane
      display="flex"
      alignItems="center"
      justifyContent="center"
      height={200}
    >
      <Spinner theme={theme} />
    </Pane>
  );
};

export default Loading;
