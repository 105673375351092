import React from 'react';
import { defaultTheme } from 'evergreen-ui';

const theme = {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    background: {
      ...defaultTheme.colors.background,
      tint0: '#FFFFFF',
    },
  },
  spinnerColor: 'hotpink',
};

const ThemeContext = React.createContext(theme);

export default ThemeContext;
