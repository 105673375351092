import { useContext } from 'react';
import ThemeContext from 'src/components/ThemeContext';
import { Link, routes } from '@redwoodjs/router';
import { Pane, Text, Heading, minorScale } from 'evergreen-ui';

const Header = ({ children }) => {
  const theme = useContext(ThemeContext);
  return (
    <Pane
      display="flex"
      padding={minorScale(3)}
      elevation={1}
      background={theme.colors.background.tint0}
      height={56}
    >
      <Pane flex={1} alignItems="center" display="flex">
        <Heading size={600}>
          <Link to="/">Brayden Jayden</Link>
        </Heading>
      </Pane>
      <Pane display="flex" alignItems="center">
        <Pane marginRight={minorScale(4)}>
          <Link to={routes.rosters()}>
            <Text>Rosters</Text>
          </Link>
        </Pane>
        <Pane marginRight={minorScale(4)}>
          <Link to={routes.players()}>
            <Text>Players</Text>
          </Link>
        </Pane>
        {children}
      </Pane>
    </Pane>
  );
};

export default Header;
